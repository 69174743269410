import {Application} from '@hotwired/stimulus'
import {registerControllers} from 'stimulus-vite-helpers'
import "trix"
import "@rails/actiontext"
import "../stylesheets/application.css"
import ScrollProgress from '@stimulus-components/scroll-progress'
import * as Turbo from '@hotwired/turbo'
import "flowbite/dist/flowbite.turbo.js";
import * as ActiveStorage from "@rails/activestorage";
import posthog from 'posthog-js';
import {Amphora, Award, Axe, Backpack, BookCopy, BookMarked, BookOpenCheck, Castle, Coins, Computer, createIcons, Crown, Dices, FlaskRound, Flower, Gamepad2, Ghost, HandCoins, HeadphoneOff, Headphones, Headset, Hourglass, Joystick, Medal, MemoryStick, PcCase, Pickaxe, Puzzle, ScrollText, ShieldBan, Shovel, Skull, Sparkles, Sprout, Star, Sword, Swords, Tally1, Tally2, Tally3, Tally4, Tally5, Target, VenetianMask, Mic, MicOff, Theater, Waypoints, Activity, Cake, Bot, BotOff, BotMessageSquare, HandMetal, PartyPopper, Biohazard, Radiation, Brain, FlaskConical, FlaskConicalOff, Tangent, Repeat2, ExternalLink, Rss, RailSymbol, Podcast, CheckCheck} from "lucide";


console.log('Vite ⚡️ Rails')

if (import.meta.hot) {
  import.meta.hot.on("vite:beforeFullReload", (payload) => {
    // Hack to skip the reload of the page
    // See https://github.com/vitejs/vite/blob/f402cd278c861e6351b27966d4f98bbb8bc34673/packages/vite/src/client/client.ts#L229
    payload.path = "wont_match_current_path.html"
    Turbo.cache.clear()

    // Maintain scroll position
    const scrollTop = document.documentElement.scrollTop
    addEventListener(
      "turbo:visit",
      () => {
        document.documentElement.scrollTop = scrollTop
      },
      {once: true}
    )

    Turbo.visit(location.pathname, {action: "replace"})
  })
}

const application = Application.start();
application.debug = true;
application.warnings = true;
window.Stimulus = application;

application.register('scroll-progress', ScrollProgress)

try {
  const controllers = import.meta.glob("~/controllers/**/*_controller.js", {
    eager: true,
  });
  console.log(controllers)
  registerControllers(application, controllers);
} catch (error) {
  console.error("Error registering Stimulus controllers:", error);
}

ActiveStorage.start();
Turbo.start()

const isDevelopment = () => {
  return import.meta.env.RAILS_ENV === "development";
}

if (!isDevelopment()) {
  // Note: this does not rerun on every navigation. Only reloads on a full page refresh, not sure if that matters. Will probably save api calls this way tbh.
  window.posthog = posthog
  posthog.init('phc_VIdvqdf8T9AUYcCbe92tkJKGdnEsKBLYFpvXbNizZkI', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only'
  })

  if (window.currentUser) {
    posthog.identify(window.currentUser?.profile?.gamertag, window.currentUser)
  }
}

document.addEventListener("turbo:load", () => {
  createIcons({icons: {Gamepad2, Skull, VenetianMask, Amphora, Axe, Castle, Swords, Sword, Star, Medal, Tally1, Tally2, Tally3, Tally4, Tally5, Target, FlaskRound, Computer, Award, Pickaxe, BookCopy, Coins, Crown, Backpack, Flower, Joystick, Sprout, MemoryStick, BookOpenCheck, Ghost, Sparkles, BookMarked, HandCoins, Headset, Headphones, HeadphoneOff, PcCase, Dices, ScrollText, Hourglass, ShieldBan, Puzzle, Shovel, Mic, MicOff, Theater, Waypoints, Activity, Cake, Bot, BotOff, BotMessageSquare, HandMetal, PartyPopper, Biohazard, Radiation, Brain, FlaskConical, FlaskConicalOff, Tangent, Repeat2, ExternalLink, Rss, RailSymbol, Podcast, CheckCheck}});
})
// document.addEventListener("turbo:load", () => {
//   // Select all Trix editor elements
//   document.querySelectorAll("trix-editor").forEach((editor) => {
//     if (!editor.editor) {
//       new window.Trix.Editor(editor);
//     }
//   });
// });
//
document.addEventListener("turbo:before-render", (event) => {
  createIcons({icons: {Gamepad2, Skull, VenetianMask, Amphora, Axe, Castle, Swords, Sword, Star, Medal, Tally1, Tally2, Tally3, Tally4, Tally5, Target, FlaskRound, Computer, Award, Pickaxe, BookCopy, Coins, Crown, Backpack, Flower, Joystick, Sprout, MemoryStick, BookOpenCheck, Ghost, Sparkles, BookMarked, HandCoins, Headset, Headphones, HeadphoneOff, PcCase, Dices, ScrollText, Hourglass, ShieldBan, Puzzle, Shovel, Mic, MicOff, Theater, Waypoints, Activity, Cake, Bot, BotOff, BotMessageSquare, HandMetal, PartyPopper, Biohazard, Radiation, Brain, FlaskConical, FlaskConicalOff, Tangent, Repeat2, ExternalLink, Rss, RailSymbol, Podcast, CheckCheck}});
})
// document.addEventListener("turbo:before-render", (event) => {
//   // Save the current state of Trix editors before Turbo morphs the page
//   event.detail.newBody.querySelectorAll("trix-editor").forEach((newEditor, index) => {
//     const existingEditor = document.querySelector(`trix-editor:nth-of-type(${index + 1})`);
//     if (existingEditor && existingEditor.editor) {
//       console.log(existingEditor);
//       console.log(newEditor)
//       newEditor.inputElement.value = existingEditor.editor.getDocument().toString();
//     }
//   });
// });